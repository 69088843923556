<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="SEGMENT"
        :filter="filter"
        placeholder="Search Name"
        :hasFilter="false"
        :hasDropdown="false"
        @search="handleSearch"
        routerPath="/segment/details/0"
      />

      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(name)="data">
                <router-link :to="'/segment/details/' + data.item.id">
                  <u> {{ data.item.name }}</u>
                </router-link>
                <span v-if="data.item.is_process_done == '0'" class="ml-2">
                  <b-spinner class="align-middle" small></b-spinner
                ></span>
              </template>
              <template v-slot:cell(number_of_user)="data">
                <span>
                  {{ data.item.number_of_user | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(is_auto_update)="data">
                <span
                  :class="
                    data.item.is_auto_update == 1
                      ? 'text-success'
                      : 'text-error'
                  "
                >
                  {{ data.item.is_auto_update == 1 ? "Active" : "Inactive" }}
                </span>
              </template>

              <template v-slot:cell(last_updated)="data">
                <span>{{
                  $moment(data.item.last_updated).format("DD/MM/YYYY")
                }}</span>
                <br />
                <small
                  >({{
                    $moment(data.item.last_updated).format("HH:mm:ss")
                  }})</small
                >
              </template>

              <template v-slot:cell(action)="data">
                <div class="d-flex justify-content-center">
                  <router-link :to="'/segment/details/' + data.item.id">
                    <b-button variant="link" class="text-warning px-1 py-0">
                      <font-awesome-icon icon="pencil-alt" title="Edit" />
                    </b-button>
                  </router-link>
                  <b-button
                    variant="link"
                    class="text-danger px-1 py-0"
                    @click="AlertDelete(data.item.id)"
                  >
                    <font-awesome-icon
                      icon="trash-alt"
                      title="Delete"
                      class="main-color"
                    />
                  </b-button>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import Pagination from "@/components/Pagination";

export default {
  components: {
    OtherLoading,
    Pagination,
  },
  name: "segmentIndex",
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Segment Name",
          tdClass: "text-center",
        },
        {
          key: "number_of_user",
          label: "Number Of User",
          tdClass: "text-center",
        },
        {
          key: "last_updated",
          label: "Last Updated",
          tdClass: "text-center",
        },
        {
          key: "is_auto_update",
          label: "Auto Update",
          tdClass: "text-center",
        },
        {
          key: "action",
          label: "",
          class: "w-1",
        },
      ],
      items: [],
      isBusy: false,
      isLoadingData: false,
      rows: 0,
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      isLoading: true,
    };
  },
  created: async function () {
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      this.isLoadingData = true;
      await this.$store.dispatch("getSegmentList", this.filter);
      var data = await this.$store.state.segment.segmentList;
      this.isLoading = false;
      if (data.result == 1) {
        this.items = data.detail.data;
        this.rows = data.detail.count;
        this.isLoadingData = false;
      }
      this.isBusy = false;
    },
    handleSearch(value) {
      this.filter = value;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
      this.getList();
    },
    AlertDelete(id) {
      this.confirmAlert().then((val) => {
        if (val.isConfirmed) {
          this.deleteData(id);
        }
      });
    },
    deleteData: async function (id) {
      this.isLoadingData = true;
      await this.$store.dispatch("deleteSegment", id);
      var data = await this.$store.state.segment.stateDeleteSegment;
      if (data.result == 1) {
        this.isLoadingData = false;
        this.successAlert().then(() => {
          this.getList();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
}
.time {
  color: #afafaf;
}
</style>
